@import "all";

.Language {
  .language-container {
    background: #fff;
    font-family: $font-body;
    font-weight: bold;
    color: #a0a0a0;
    margin-left: 5px;
    position: fixed;
    padding-left: 7px;
    padding-right: 7px;
    height: auto;
    width: auto;
    z-index: 1;
  }

  .language-container a {
    color: $inactive-link-color !important;
    text-decoration: none;
  }

  .language-container a.active {
    color: $body-text-color !important;
    text-decoration: none;
  }

  .btn-link{
    float:left;
    padding:5px 11px;
    font-weight: bold;
  }

  .btn-maroon {
    color: #000 !important;
    border: none !important;
  }
  @include media-breakpoint-down(sm) {
    .language-container {
      margin-left: 11.8px;
    }
    .btn-link {
      font-size: 11.5px;
      padding:5px 7px;

    }

  }
}
