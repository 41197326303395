@import "all";

.Desktop {
  background: #bebebe;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100vh;

  .mt-6 {
    margin-top: $spacer-gap !important;
  }

  @include media-breakpoint-down(sm) {
    display: none !important;


  }
}
