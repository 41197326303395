@import url("https://fonts.googleapis.com/css?family=PT+Sans&display=swap");
@font-face {
  font-family: "indira_kregular";
  src: url("../../assets/fonts/indira_k-webfont.woff2") format("woff2"),
    url("../../assets/fonts/indira_k-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.container {
  max-width: 1292px !important;
}

body,
html {
  height: 100%;
  min-width: 320px;
}

#root,
.App,
.App > div:not(.col) {
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .App {
    min-height: 400px;
  }
  .container {
    padding: 0 !important;
  }
  .row {
    width: 97% !important;
  }
}
