@import "all";

.SiteTitle {
  .logo-container {
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    background: $base-color;
    text-align: left;
    width: 287px;
    padding-left: 0;
    z-index: 1;

    img {
      width: 100%;
    }
  }

  @include media-breakpoint-down(sm) {
    .logo-container {
      width: 167px;
      padding-left: 0;
    }
  }
}
